import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, IconButton, Tooltip } from '@mui/material';
import './homecare.css';
import {
  CreateAppointmentApi,
  createNewInvoice,
  getAllDriversList,
  getCashBankAccounts,
  getCustomerList,
  getAllPractitioners,
  getPriceListByID,
  getUnassignedNursesList,
  updateAppoinmentStatus,
  updateEmrItemsStatus,
  updateVisitInvoice
} from '../components/ApiMethods';

import AppLoader from '../../../components/AppLoader';
import SharedInput from '../components/SharedInput';
import SharedDropdown from '../components/SharedDropdown';
import SharedSearchDropdown from './SharedSearchDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import priceList from '../../../gql/priceList';
import { BiSearch, BiX } from 'react-icons/bi';
import _, { values } from 'lodash';
import { FaArrowLeft } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getCurrentDateTime } from './NewAppointment';
import toast from 'react-hot-toast';
import { Medication } from '@mui/icons-material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { truncateText } from '../../../util/truncateText';

function HomeAppointment() {
  const [open, setOpen] = useState(false);
  const [payerId, setPayerID] = useState('2117');
  const formikRef = useRef(null);
  const [ref, setRef] = useState('');
  const [patientType, setPatientType] = useState(null);
  const navigate = useNavigate();
  const [priceListId, setPriceListId] = useState('0');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [payerLicense, setPayerLicense] = useState('2117');
  const [cashAccount, setCashAccounts] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [validate, setValidate] = useState('Cash');
  const [maxLimit, setMaxLimit] = useState('100');
  const [paidToday, setPaidToday] = useState(true);
  const location = useLocation();
  const appointmentBillToPay = location?.state?.appointment;
  const selectedOrderDetails = location?.state?.selectedOrder;
  const orderNumber = selectedOrderDetails?.order_no ?? '';
  const orderItems = selectedOrderDetails?.items ?? [];
  const preSelectedPatient = selectedOrderDetails?.patient;

  const formattedOrderItems = orderItems?.map(item => {
    return {
      checked: item?.checked || false,
      discount_amount: item?.discount_amount,
      discount_type: item?.discount_type ?? 'fixed',
      factor: item?.factor,
      id: item?.item_id,
      non_standard_code: item?.non_standard_code,
      non_standard_desc: item?.name,
      patient_share: 20,
      payer_id: item?.payer_id ?? '',
      quantity: item?.quantity,
      service_code: item?.non_standard_code,
      service_desc: item?.name,
      service_type: item?.type,
      tax_check: item?.tax_check ?? false,
      unit_price: item?.unit_price
    };
  });
  const hisPayers =
    localStorage.getItem('hisPayers') && localStorage.getItem('hisPayers') !== 'undefined'
      ? JSON.parse(localStorage.getItem('hisPayers'))
      : [];

  const selectedPayer = hisPayers?.find(
    payer => payer?.payer_id == selectedOrderDetails?.patient?.insurance_plans[0]?.payer_id
  );

  const {
    loading: loadingSearchPriceListCode,
    error: errorSearchPriceListCode,
    data: dataSearchPriceListCode,
    refetch: refetchSearchPriceListCode
  } = useQuery(priceList.SEARCH_PRICE_LIST_CODE, {
    variables: {
      input: '',
      payerId: payerId
    },
    skip: true
  });
  const handleSearch = term => {
    refetchSearchPriceListCode({
      input: term,
      payerId: payerId
    }).then(data => {
      setSearchResult(data?.data?.searchPriceListCode?.data || []);
    });
  };

  // /imageHis/pngtree-nurse.png

  const onDragEnd = result => {
    const { source, destination } = result;

    console.log(result);
    if (!source && !destination) return;

    if (source?.droppableId === destination?.droppableId && source?.index === destination?.index) {
      return;
    }

    const updatedDrivers = [...drivers];
    const newNurses = [...nurses];

    if (source.droppableId === 'nurses' && destination && destination?.droppableId != 'nurses') {
      const [nurse] = newNurses.splice(source.index, 1);
      const targetDriver = updatedDrivers?.find(data => data?.driver_id == destination?.droppableId);

      if (targetDriver) {
        targetDriver.assigned_nurses = targetDriver?.assigned_nurses ? [...targetDriver?.assigned_nurses] : [];
        targetDriver?.assigned_nurses.push(nurse);
      }
    }

    if (!destination || destination?.droppableId == 'nurses') {
      const targetDriver = updatedDrivers?.find(data => data?.driver_id == source?.droppableId);
      const targetNurse = targetDriver
        ? targetDriver?.assigned_nurses?.find(data => data?.nurse_id == result?.draggableId)
        : null;

      if (targetNurse) {
        const targetNurseIndex = targetDriver?.assigned_nurses?.findIndex(
          data => data?.nurse_id == result?.draggableId
        );
        if (targetNurseIndex != -1) {
          targetDriver?.assigned_nurses.splice(targetNurseIndex, 1);
          const targetDriverIndex = updatedDrivers.findIndex(data => data.driver_id == source?.droppableId);
          if (targetDriverIndex != -1) {
            updatedDrivers[targetDriverIndex] = targetDriver;
            newNurses.push(targetNurse);
          }
        }
      }
    }
    console.log(updatedDrivers);
    setNurses(newNurses);
    setDrivers(updatedDrivers);
  };

  const calculateCashDetails = formikProps => {
    const items = formikProps?.values?.items || [];

    const detailedItems = items.map(itm => {
      const unitPrice = Number(itm.unit_price) || 0;
      const quantity = parseFloat(itm.quantity) || 0;

      const itemAmount = unitPrice * quantity;

      const discountAmount = Number(itm?.discount_amount) || 0;
      const discountType = itm?.discount_type || '';
      let itemDiscount = 0;

      if (discountType === 'percentage') {
        itemDiscount = (unitPrice * quantity * discountAmount) / 100;
      } else if (discountType === 'fixed') {
        itemDiscount = discountAmount;
      }

      // Calculate tax for the specific item
      const itemTax = itm.tax_check ? CalculateTax({ values: { items: [itm] } }) : 0;

      const itemNetTotal = itemAmount - itemDiscount + itemTax;

      return {
        unitPrice,
        quantity: Number(quantity),
        item_code: itm?.service_code || '',
        item_name: itm?.service_desc || '',
        item_type: itm?.service_type || '',
        discount_type: discountType,
        discountAmount: Number(itemDiscount.toFixed(2)),
        discountedAmount: parseFloat((itemAmount - itemDiscount).toFixed(2)),
        itemAmount: parseFloat(itemAmount.toFixed(2)),
        patientShare: parseFloat(itemNetTotal.toFixed(2)),
        companyShare: 0,
        patientTax: parseFloat(itemTax.toFixed(2)),
        companyTax: 0
      };
    });

    const totalAmount = detailedItems.map(itm => itm.itemAmount).reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = detailedItems.map(itm => itm.discountAmount).reduce((acc, curr) => acc + curr, 0);

    const totalTax = detailedItems.map(itm => itm.patientTax).reduce((acc, curr) => acc + curr, 0);

    const netTotal = detailedItems.map(itm => itm.patientShare).reduce((acc, curr) => acc + curr, 0);

    return {
      itemBreakdown: detailedItems,
      totals: {
        patientShareTotal: parseFloat(totalAmount.toFixed(2)),
        companyShareTotal: 0,
        patientTaxTotal: parseFloat(totalTax.toFixed(2)),
        companyTaxTotal: 0,
        discountedAmount: parseFloat(totalDiscount.toFixed(2)),
        totalAmount:
          parseFloat(totalAmount.toFixed(2)) - parseFloat(totalDiscount.toFixed(2)) + parseFloat(totalTax.toFixed(2))
      }
    };
  };

  const getNationality = () => {
    const patient = patientList?.data?.find(item => item.id === ref);
    return patient ? patient.nationality : null;
  };

  function calculateShares(formikProps, maxLimit) {
    const items = formikProps?.values?.items || [];
    let totalPatientShare = 0;
    let totalCompanyShare = 0;
    let totalPatientTax = 0;
    let totalCompanyTax = 0;
    let totalDiscountedAmount = 0;
    const nationality = getNationality();
    const itemBreakdown = items.map(itm => {
      const unitPrice = Number(itm?.unit_price) || 0;
      const quantity = itm?.quantity || 0;
      const discountAmount = itm?.discount_amount || 0;
      const patientSharePercentage = itm?.patient_share;

      const totalAmount = unitPrice * quantity;
      let discountedAmount;

      if (itm?.discount_type === 'fixed') {
        discountedAmount = totalAmount - discountAmount;
        totalDiscountedAmount += discountAmount;
      } else {
        const discountValue = (totalAmount * discountAmount) / 100;
        totalDiscountedAmount += discountValue;
        discountedAmount = totalAmount - discountValue;
      }

      const fullPatientShare = (discountedAmount * patientSharePercentage) / 100;
      let currentPatientShare = 0;
      let currentCompanyShare = 0;

      if (totalPatientShare < maxLimit) {
        const remainingLimit = maxLimit - totalPatientShare;
        if (fullPatientShare <= remainingLimit) {
          currentPatientShare = fullPatientShare;
        } else {
          currentPatientShare = remainingLimit;
        }
        totalPatientShare += currentPatientShare;
      }

      currentCompanyShare = discountedAmount - currentPatientShare;
      totalCompanyShare += currentCompanyShare;

      let patientTax = 0;
      let companyTax = currentCompanyShare * 0.15;

      if ((nationality === 'saudi' || nationality === 'sa') && itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      } else if (itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      }

      totalPatientTax += patientTax;
      totalCompanyTax += companyTax;

      return {
        item_code: itm?.non_standard_code || '',
        item_name: itm?.service_desc || '',
        item_type: itm?.service_type || '',
        discount_type: itm?.discount_type,
        unitPrice,
        quantity: Number(quantity),
        totalAmount: parseFloat(totalAmount.toFixed(2)),
        discountAmount: parseFloat(discountAmount),
        discountedAmount: parseFloat(discountedAmount.toFixed(2)),
        patientShare: parseFloat(currentPatientShare.toFixed(2)),
        companyShare: parseFloat(currentCompanyShare.toFixed(2)),
        patientTax: parseFloat(patientTax.toFixed(2)),
        companyTax: parseFloat(companyTax.toFixed(2))
      };
    });

    return {
      itemBreakdown,
      totals: {
        patientShareTotal: parseFloat(totalPatientShare.toFixed(2)),
        companyShareTotal: parseFloat(totalCompanyShare.toFixed(2)),
        patientTaxTotal: parseFloat(totalPatientTax.toFixed(2)),
        companyTaxTotal: parseFloat(totalCompanyTax.toFixed(2)),
        discountedAmount: Number(totalDiscountedAmount),
        totalAmount:
          parseFloat(totalPatientShare.toFixed(2)) +
          parseFloat(totalCompanyShare.toFixed(2)) +
          parseFloat(totalPatientTax.toFixed(2)) +
          parseFloat(totalCompanyTax.toFixed(2))
      }
    };
  }

  useEffect(() => {
    getUnassignedNursesList(setLoading, setNurses);
    getAllDriversList(setLoading, setDrivers);
    getCashBankAccounts(setLoading, setCashAccounts);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getuser = localStorage.getItem('user');
  const user = JSON.parse(getuser);

  const appointmentTypes = [
    { label: 'Routine checkup', value: 'Routine checkup' },
    { label: 'Follow up', value: 'Follow up' },
    { label: 'Consultation', value: 'Consultation' },
    { label: 'Emergency', value: 'Emergency' },
    { label: 'Procedure', value: 'Procedure' },
    { label: 'Therapy', value: 'Therapy' },
    { label: 'Immunization', value: 'Immunization' },
    { label: 'Surgical', value: 'Surgical' },
    { label: 'Diagnostic test', value: 'Diagnostic test' }
  ];

  const paymentMethods = [
    { label: 'Mada', value: 'Mada' },
    { label: 'Cash', value: 'cash' },
    { label: 'Credit', value: 'credit' },
    { label: 'Bank', value: 'bank' }
  ];

  useEffect(() => {
    getCustomerList(setLoading, setPatientList);
    getAllPractitioners(setLoading, setDoctorList);
    fetchPriceList(priceListId);
  }, []);

  useEffect(() => {
    if (patientList?.data && appointmentBillToPay?.patient?.patient_id) {
      setRef(patientList.data.find(patient => patient.his_refrence === appointmentBillToPay?.patient?.patient_id)?.id);
      const selectedPatient = patientList?.data?.find(
        patient => patient.his_refrence === appointmentBillToPay?.patient?.patient_id
      );
      setPatientType(selectedPatient.beneficiary_type);
      setValidate(selectedPatient.beneficiary_type);
    }
  }, [patientList, appointmentBillToPay]);

  const initialValues = {
    listInvoices: false,
    AppoinmentType: appointmentBillToPay ? appointmentBillToPay?.patient?.beneficiary_type : 'Cash',
    service_categories: appointmentBillToPay ? appointmentBillToPay?.service_categories : '',
    service_type: '',
    visit_type: appointmentBillToPay ? appointmentBillToPay?.visit_type?.type : 'Consultation',
    reasons: '',
    speciality: appointmentBillToPay
      ? appointmentBillToPay?.specialty?.specialty
      : selectedOrderDetails?.specialty || '',
    practitionerName: appointmentBillToPay ? appointmentBillToPay?.practitionerName : '',
    practitionerRole: appointmentBillToPay ? appointmentBillToPay?.practitionerRole : '',
    visit_date: appointmentBillToPay ? appointmentBillToPay?.visit_date : getCurrentDateTime(),
    slots: appointmentBillToPay ? appointmentBillToPay?.start : '',
    customer_id: appointmentBillToPay ? appointmentBillToPay.patient?.patient_id : preSelectedPatient?.patient_id || '',
    customer_name: appointmentBillToPay ? appointmentBillToPay?.patient?.patient_name : '',
    practitioner_id: appointmentBillToPay
      ? appointmentBillToPay?.practitioner_id
      : selectedOrderDetails?.practitioner_id || '',
    comment: appointmentBillToPay ? appointmentBillToPay?.comment : '',
    //date: getCurrentDateTime(),
    payment_method: appointmentBillToPay?.payment_method ? appointmentBillToPay?.payment_method : 'cash',
    description: '',
    paid: !appointmentBillToPay || appointmentBillToPay.payment_status === 'Paid',
    branch_id: 1,
    business_id: 1,
    invoice_type: 'sales',
    insurancePlanId: appointmentBillToPay?.insurancePlanId ? appointmentBillToPay?.insurancePlanId : '',
    payerId: appointmentBillToPay?.payerId ? appointmentBillToPay?.payerId : selectedPayer?.rcm_ref || '',
    payerName: appointmentBillToPay?.payerName ? appointmentBillToPay?.payerName : selectedPayer?.name || '',
    insurancePlanIndex: appointmentBillToPay?.insurancePlanIndex ? appointmentBillToPay?.insurancePlanIndex : '',
    items: appointmentBillToPay?.items?.filter(item => item?.status === 'UnPaid') || formattedOrderItems,
    company_id: payerId
  };

  const validationSchema = yup.object().shape({
    AppoinmentType: paidToday ? yup.string().required('Appointment Type is required') : null,
    visit_type: yup.string().required('Appointment Type is required'),
    speciality: yup.string().required('Speciality is required'),
    payment_method: yup.string().when('listInvoices', {
      is: true,
      then: () => yup.string().required('Payment method is required'),
      otherwise: () => yup.string().notRequired()
    }),
    visit_date: yup.string().required('Start date is required'),
    customer_id: yup.string().required('Patient is required'),
    practitioner_id: yup.string().required('Practitioner is required'),
    comment: yup.string(),
    //date: paidToday ? yup.string().required('Date is required ') : null,
    paid: yup.string().required('Paid status is required ')
    //items: paidToday ? yup.array().min(1, 'At least one item is required') : null
  });

  useEffect(() => {
    if (preSelectedPatient) {
      let selectedPatientRef = patientList?.data?.find(data => data?.id == preSelectedPatient?.patient_id);
      formikRef?.current?.setFieldValue('customer_id', selectedPatientRef?.his_refrence);
      if (selectedPatientRef) {
        formikRef?.current?.setFieldValue('AppoinmentType', selectedPatientRef?.beneficiary_type);
      }
      if (preSelectedPatient?.beneficiary_type) {
        setValidate(preSelectedPatient?.beneficiary_type);
      }
      setRef(preSelectedPatient?.patient_id);
    }
  }, [preSelectedPatient, patientList]);

  useEffect(() => {
    if (selectedPayer) {
      setPayerID(selectedPayer?.rcm_ref);
      setPayerLicense(selectedPayer?.license_id);
      formikRef?.current?.setFieldValue('payerId', selectedPayer?.rcm_ref);
      formikRef?.current?.setFieldValue('payerName', selectedPayer?.name);
    }
  }, [selectedPayer]);

  function CalculateTax(formikProps) {
    const items = formikProps?.values?.items || [];

    const taxableAmount = items
      .filter(itm => itm?.tax_check)
      .map(itm => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .filter(itm => itm?.tax_check)
      .map(itm => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === 'percentage') {
          discount = (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) / 100;
        } else if (discountType === 'fixed') {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxableAmountAfterDiscount = Math.max(taxableAmount - totalDiscount, 0);

    const taxAmount = (taxableAmountAfterDiscount * 15) / 100;

    return taxAmount;
  }

  function CalculateNetTotal(formikProps) {
    const items = formikProps?.values?.items || [];

    const totalAmount = items
      .map(itm => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .map(itm => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === 'percentage') {
          discount = (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) / 100;
        } else if (discountType === 'fixed') {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxAmount = CalculateTax(formikProps);

    const netTotal = Math.max(totalAmount - totalDiscount + taxAmount, 0);
    return netTotal;
  }

  useEffect(() => {
    validate === 'Cash' && setPayerID('2117');
  }, [validate]);

  const handleSubmit = async (values, { resetForm }) => {
    if (!values?.items?.length) {
      toast.error('At least one item is required');
      return;
    }

    if (values?.listInvoices && values?.paid && !values?.account_code) {
      toast.error('Please select account');
      return;
    }

    const ifItemIsSelected = values?.items?.some(data => data?.checked == true);

    let assigned_drivers = drivers?.filter(data => data?.assigned_nurses && data?.assigned_nurses?.length);

    if (!assigned_drivers?.length) {
      toast.error('Please dispatch at least one medical staff with driver');
      return;
    }

    if (!ifItemIsSelected) {
      toast.error('Please checked at least one item to create special visit');
      return;
    }

    let assigned_drivers_payload = {
      drivers: assigned_drivers,
      check: assigned_drivers?.length ? true : false
    };

    let paymentStatus = selectedOrderDetails?.invoice_check ? 'Paid' : 'UnPaid';

    let paymentMethod = selectedOrderDetails?.invoice_check
      ? selectedOrderDetails?.payment_method
      : values?.payment_method;

    if (!selectedOrderDetails?.invoice_check && !values?.listInvoices) {
      paymentStatus = 'UnPaid';
    }

    if (values?.listInvoices) {
      paymentStatus = values?.paid ? 'Paid' : 'UnPaid';
    }
    const appointmentData = {
      service_category: {
        category: values?.service_categories
      },
      order_no: orderNumber,
      assigned_drivers: assigned_drivers_payload,
      service_type: { type: values?.service_type },
      specialty: { specialty: values?.speciality },
      visit_type: { type: values?.visit_type },
      reason_code: { code: values?.reasons },
      visit_date: values?.visit_date,
      start: values?.slots,
      participant: { participant: '' },
      comment: values?.comment,
      patient_id: values?.customer_id ? String(values.customer_id) : '',
      visit_category: 'special_patient',
      practitioner_id: values?.practitioner_id,
      payment_method: paymentMethod,
      payment_status: paymentStatus,
      items: values?.items.map(item => ({
        ...item,
        discount_amount: Number(item?.discount_amount),
        patient_share: Number(item?.patient_share),
        name: item?.service_desc,
        item_code: item?.non_standard_code,
        item_type: item?.service_type,
        quantity: Number(item?.quantity),
        unit_price: Number(item?.unit_price),
        id: Number(item?.id),
        checked: item?.checked,
        status: paymentStatus === 'Paid' ? 'Paid' : 'UnPaid'
      }))
    };

    const commonData = {
      date: values?.visit_date,
      // description: values?.description,
      paid: values?.paid,
      ...(formikRef?.current?.values?.paid === true && {
        account_code: values?.account_code
      }),
      branch_id: values?.providers_branch_id,
      business_id: values?.providers_id,
      item_details:
        validate === 'Cash' ? calculateCashDetails(formikRef?.current) : calculateShares(formikRef?.current, maxLimit),
      customer_id: Number(ref),
      payment_method: values?.payment_method,
      tax_type: 'percentage',
      tax_amount: 15
    };

    const invoiceData =
      values.AppoinmentType === 'Cash'
        ? {
            ...commonData,
            invoice_type: 'sales'
          }
        : {
            ...commonData,
            invoice_type: 'credit',
            company_id: Number(payerLicense)
          };

    try {
      setLoading(true);
      if (paidToday) {
        if (paidToday && appointmentBillToPay) {
          await updateAppoinmentStatus({
            setShowLoader: setLoading,
            data: {
              visit_id: appointmentBillToPay?.visit_id,
              payment_status: 'Paid'
            }
          }).then(async res => {
            updateEmrItemsStatus(setLoading, {
              items: commonData?.items,
              visit_id: appointmentBillToPay?.visit_id
            });
            if (values?.listInvoices) {
              const invoiceResponse = await createNewInvoice(setLoading, invoiceData);
              await updateVisitInvoice(setLoading, {
                id: appointmentBillToPay?.visit_id,
                invoice_number: invoiceResponse?.data?.invoice?.invoice_number,
                invoice_id: invoiceResponse?.data?.invoice?.id,
                company_invoice_number: invoiceResponse?.data?.companyInvoice?.id,
                items: appointmentData.items
              });
            }
          });
          navigate('/receptionist/orders-list');
          handleClose();
          resetForm();
          setValidate('');
        } else {
          const appointmentResponse = await CreateAppointmentApi(setLoading, appointmentData).then(async res => {
            if (res?.message == 'Visit created successfully') {
              if (values?.listInvoices) {
                const invoiceResponse = await createNewInvoice(setLoading, invoiceData);

                if (invoiceResponse) {
                  await updateVisitInvoice(setLoading, {
                    id: res?.data?.visit_id,
                    invoice_number: invoiceResponse?.data?.invoice?.invoice_number,
                    invoice_id: invoiceResponse?.data?.invoice?.id,
                    company_invoice_number: invoiceResponse?.data?.companyInvoice?.id,
                    items: appointmentData.items
                  });
                }
              } else {
                await updateVisitInvoice(setLoading, {
                  id: res?.data?.visit_id,
                  invoice_number: selectedOrderDetails?.invoice_no,
                  invoice_id: selectedOrderDetails?.invoice_id,
                  company_invoice_number: selectedOrderDetails?.company_invoice_no,
                  items: appointmentData.items
                });
              }
              navigate('/receptionist/orders-list');
              handleClose();
              resetForm();
              setValidate('');
            }
          });
        }
      } else {
        const appointmentResponse = await CreateAppointmentApi(setLoading, appointmentData);
        navigate('/receptionist/orders-list');
      }
    } catch (error) {
      console.error('Error during appointment or invoice creation:', error);
    } finally {
      setLoading(false);
    }
  };
  const typeOptions = [
    { value: 'fixed', label: 'Fixed' },
    { value: 'percentage', label: 'Percentage' }
  ];

  const prevPriceListIdRef = useRef();

  const fetchPriceList = useCallback(id => {
    if (id && id !== prevPriceListIdRef.current) {
      console.log('Fetching price list for ID:', id);
      getPriceListByID(
        setLoading,
        {
          payer_id: String(id)
        },
        setItemsList
      );
      prevPriceListIdRef.current = id;
    }
  }, []);
  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {formikProps => (
          <div className='pre_auth_req_page flex h-screen rounded-md bg-gray-100 p-2'>
            <div className={`${validate === '' || !paidToday ? 'w-full' : 'w-3/4'} pr-2 overflow-auto`}>
              <h2 className='text-xl font-semibold mb-2 flex items-center p-2 rounded-md text-gray-800'>
                <div className='flex justify-end items-end cursor-pointer' onClick={() => navigate(-1)}>
                  <IconButton
                    aria-label='go back'
                    className='!mr-2 !w-[30px] !h-[30px] !text-white !bg-red-400 !rounded-full'
                  >
                    <FaArrowLeft />
                  </IconButton>
                </div>
                {appointmentBillToPay ? 'Pay Bill' : ' Create Special Visit'}
              </h2>
              <Form onSubmit={formikProps.handleSubmit}>
                {/* {console.log(formikProps?.values)} */}
                <div className='bg-white rounded-lg shadow-md p-3 mb-2'>
                  <div className='grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[0.5rem]'>
                    <SharedInput
                      name='visit_date'
                      type='datetime-local'
                      label='Visit Date'
                      value={formikProps.values.start}
                      onChange={e => {
                        formikProps.handleChange(e);

                        const startDate = new Date(e.target.value);
                        const currentDate = new Date();

                        startDate.setHours(0, 0, 0, 0);
                        currentDate.setHours(0, 0, 0, 0);

                        const isInFuture = startDate > currentDate;
                        setPaidToday(!isInFuture);
                      }}
                    />
                    <SharedSearchDropdown
                      name='customer_id'
                      label='Patient'
                      optionLabel='Select Patient'
                      disabled={true}
                      readOnly={true}
                      data={patientList?.data?.map(patient => ({
                        value: patient.his_refrence,
                        label: patient?.name,
                        refrence: patient.id
                      }))}
                      onItemAdd={value => {
                        const selectedPatient = patientList?.data?.find(patient => patient.his_refrence === value);

                        formikProps.setFieldValue('AppoinmentType', selectedPatient.beneficiary_type);

                        if (selectedPatient && patientList?.data) {
                          const storedPayers = JSON.parse(localStorage.getItem('hisPayers')) || {};

                          if (
                            selectedPatient.beneficiary_type == 'Insurance' &&
                            !selectedPatient?.insurance_plans?.length
                          ) {
                            toast.error('no insurance plan!');
                          }
                          if (
                            storedPayers?.length &&
                            selectedPatient.beneficiary_type == 'Insurance' &&
                            selectedPatient?.insurance_plans?.length
                          ) {
                            const selectedPayer = storedPayers.find(
                              payer => payer?.payer_id == selectedPatient?.insurance_plans[0]?.payer_id
                            );
                            formikProps.setFieldValue('payer', selectedPayer?.rcm_ref);
                            setPayerID(selectedPayer?.rcm_ref);
                            setPayerLicense(selectedPayer?.license_id);
                          }
                        }

                        setValidate(selectedPatient.beneficiary_type);
                        setMaxLimit(selectedPatient.max_limit);
                        setRef(selectedPatient.id);
                      }}
                    />

                    {paidToday && (
                      <div className='flex gap-1 flex-col'>
                        <label className='text-sm'>Patient Type</label>

                        <div className='flex w-full'>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue('AppoinmentType', 'Cash');
                              setValidate('Cash');
                            }}
                            className={`w-[50%] border p-1.5 transition-all rounded-tl-md rounded-bl-md  !border-r-0  ${
                              formikProps.values.AppoinmentType === 'Cash'
                                ? '!bg-primary !border-primary text-white'
                                : null
                            }`}
                          >
                            Cash
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue('AppoinmentType', 'Insurance');
                              setValidate('Insurance');
                            }}
                            className={`w-[50%] border  p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                              formikProps.values.AppoinmentType === 'Insurance'
                                ? '!bg-primary !border-primary text-white'
                                : null
                            }`}
                          >
                            Insurance
                          </button>
                        </div>
                        {formikProps.errors.AppoinmentType && formikProps.touched.AppoinmentType ? (
                          <p className='max-w-2xl text-sm leading-6 text-red-500'>
                            {formikProps.errors.AppoinmentType}
                          </p>
                        ) : null}
                      </div>
                    )}
                    <SharedSearchDropdown
                      name='practitioner_id'
                      label='Doctor'
                      optionLabel='Select doctor'
                      data={doctorList?.data
                        ?.filter(data => data?.role == 'Doctor')
                        ?.map(doctor => ({
                          value: doctor.practitioner_id,
                          label: doctor.name,
                          speciality: doctor?.speciality
                        }))}
                      onItemAdd={value => {
                        const selectedDoctor = doctorList?.data?.find(doc => doc.practitioner_id === value);
                        if (selectedDoctor) {
                          formikProps.setFieldValue('speciality', selectedDoctor.speciality);

                          formikProps.setFieldValue('practitionerName', selectedDoctor?.name);

                          formikProps.setFieldValue('practitionerRole', selectedDoctor.role);
                        }
                      }}
                    />
                    {formikProps.values.AppoinmentType === 'Insurance' && paidToday && (
                      <div className='flex flex-col gap-1'>
                        <label htmlFor='payer_id'>Payer*</label>
                        <select
                          id='payer'
                          name='payer'
                          onChange={event => {
                            formikProps.handleChange(event);
                            const selectedPayer =
                              hisPayers?.length > 0 && hisPayers?.find(payer => payer?.rcm_ref === event.target.value);
                            const selectedPayerId = event.target.value;
                            setPayerID(selectedPayerId);
                            setPayerLicense(selectedPayer?.license_id);
                          }}
                          value={formikProps.values?.payerId}
                          autoComplete='payer'
                          className='w-full border rounded-lg'
                        >
                          <option value={''}>Select Payer</option>
                          {hisPayers?.length > 0 &&
                            hisPayers?.map((payer, i) => (
                              <option key={payer?.rcm_ref} value={payer?.rcm_ref}>
                                {payer?.name}
                              </option>
                            ))}
                        </select>
                        {formikProps.errors.payer && formikProps.touched.payer ? (
                          <p className='max-w-2xl text-sm leading-6 text-red-500'>{formikProps.errors.payer}</p>
                        ) : null}
                      </div>
                    )}
                    <SharedInput
                      name='speciality'
                      type='text'
                      label='Speciality'
                      placeholder={"Doctor's Speciality"}
                      disabled='true'
                    />
                    <SharedDropdown name='visit_type' label='Visit Type' optionLabel='Select' data={appointmentTypes} />
                    <SharedInput name='comment' label='Comment' placeholder={'Add Comment'} />
                    {!selectedOrderDetails?.invoice_check && (
                      <div class='toggle-button-ref flex items-end gap-2.5 justify-center mt-2'>
                        <input
                          type='checkbox'
                          id='listInvoices'
                          name='listInvoices'
                          checked={formikProps.values.listInvoices}
                          onChange={() => formikProps.setFieldValue('listInvoices', !formikProps.values.listInvoices)}
                        />
                        <label for='listInvoices'></label>
                        <span>Generate Invoice</span>
                      </div>
                    )}
                  </div>
                </div>

                {paidToday && formikProps.values.listInvoices && (
                  <div className='bg-white rounded-lg shadow-md p-6 my-2'>
                    <h3 className='mb-2 font-semibold'>Invoice Details</h3>
                    <div className={`grid  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[0.5rem]`}>
                      {/* <SharedInput name='date' label='Date' type='datetime-local' /> */}
                      <div>
                        <label className='text-sm'>Paid</label>

                        <div className='flex w-full'>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue(`paid`, true);
                            }}
                            className={`w-[50%] border p-2 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                              formikProps.values.paid === true ? '!bg-primary !border-primary text-white' : ''
                            }`}
                          >
                            Paid
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue(`paid`, false);
                            }}
                            className={`w-[50%] border p-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                              formikProps.values.paid === false ? '!bg-primary !border-primary text-white' : ''
                            }`}
                          >
                            Unpaid
                          </button>
                        </div>
                      </div>
                      <SharedDropdown
                        name='payment_method'
                        label='Payment Method'
                        optionLabel='Select'
                        data={paymentMethods}
                      />
                      {formikProps?.values?.paid && (
                        <SharedSearchDropdown
                          name='account_code'
                          label='Account'
                          optionLabel='Select Account'
                          data={cashAccount?.data?.map(acc => ({
                            value: Number(acc.code),
                            label: acc.name_en
                          }))}
                        />
                      )}
                      {/* <SharedInput name='description' label='Description' placeholder={'Enter Description'} /> */}
                    </div>
                  </div>
                )}

                <div className='flex flex-col gap-2'>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <h2 className='text-xl font-semibold flex items-center p-1 rounded-md text-gray-800'>
                      Dispatching
                    </h2>
                    <div className='grid grid-cols-2 gap-3 pb-3'>
                      <div className='card bg-white shadow-md border border-gray-300 rounded-lg p-4 h-full'>
                        <h2 className='text-xl font-semibold'>Medical Staff</h2>
                        <Droppable droppableId='nurses'>
                          {provided => (
                            <div
                              className='flex flex-col gap-2 mt-2 max-h-[400px] overflow-y-auto'
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {nurses?.length ? (
                                nurses?.map((nurse, index) => (
                                  <Draggable key={nurse?.nurse_id} draggableId={nurse?.nurse_id} index={index}>
                                    {provided => (
                                      <div
                                        className='nurse-card bg-gray-200 text-gray-700 border border-gray-300 rounded-lg flex items-center cursor-pointer hover:shadow-lg transition-shadow min-h-[50px]'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className='w-[25px] h-[25px] rounded-full ml-2 bg-orange-500 flex items-center justify-center mr-2'>
                                          <Medication className='!w-[18px] !h-[18px] rounded-full text-white' />
                                        </div>
                                        <div>
                                          <h3 className='font-bold text-md'>{nurse?.name}</h3>
                                          <p className='text-xs text-gray-400'>{nurse?.specialization || 'N/A'}</p>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))
                              ) : (
                                <div className='mt-4'>
                                  <p className='text-center'>No Data Found</p>
                                </div>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>

                      {/* Available Drivers Column */}
                      <div className='card bg-white shadow-md border border-gray-300 rounded-lg p-4 h-full'>
                        <h2 className='text-xl font-semibold'>Available Drivers</h2>
                        <div className='flex flex-col gap-2 mt-2 max-h-[400px] overflow-y-auto'>
                          {drivers?.length ? (
                            drivers?.map((driver, index) => (
                              <Droppable key={driver?.driver_id} droppableId={driver?.driver_id} direction='vertical'>
                                {provided => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className='card bg-gray-200 text-grey-700 shadow-md border border-gray-300 rounded-lg px-2 py-[6px] mt-2'
                                  >
                                    <h3 className='text-lg flex gap-1 items-center font-semibold'>
                                      {driver.name}
                                      <div className='w-[25px] h-[25px] rounded-full bg-orange-500 flex items-center justify-center mr-2'>
                                        <PeopleAltIcon className='@w-[18px] !h-[18px] rounded-full text-white' />
                                      </div>
                                      {driver?.assigned_nurses?.length > 0 && (
                                        <span className='text-xs bg-green-500 text-white w-6 h-6 flex items-center justify-center rounded-full'>
                                          {driver?.assigned_nurses?.length}
                                        </span>
                                      )}
                                    </h3>
                                    <Droppable droppableId={`${driver?.driver_id}`}>
                                      {provided => (
                                        <div
                                          className='flex flex-col gap-2 mt-2 max-h-[200px] overflow-y-auto'
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          {driver?.assigned_nurses?.map((nurse, nurseIndex) => (
                                            <Draggable
                                              key={nurse?.nurse_id}
                                              draggableId={nurse?.nurse_id}
                                              index={nurseIndex}
                                            >
                                              {provided => (
                                                <div
                                                  className='nurse-card bg-gray-200 text-gray-700 border border-gray-300 rounded-lg flex items-center cursor-pointer hover:shadow-lg transition-shadow min-h-[50px]'
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <div className='w-[25px] h-[25px] rounded-full bg-orange-500 flex items-center justify-center mr-2'>
                                                    <Medication className='!w-[18px] !h-[18px] rounded-full text-white' />
                                                  </div>
                                                  <div>
                                                    <h3 className='font-bold text-md'>{nurse?.name}</h3>
                                                    <p className='text-xs text-gray-400'>
                                                      {nurse?.specialization || 'N/A'}
                                                    </p>
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          ))}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            ))
                          ) : (
                            <div className='mt-4'>
                              <p className='text-center'>No Data Found</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </DragDropContext>
                </div>

                {paidToday && (
                  <div className='bg-white rounded-lg shadow-md p-6 mb-2'>
                    <h3 className='text-xl font-semibold mb-4 text-gray-700'>Items</h3>
                    <div className='overflow-x-auto'>
                      <table className='min-w-full divide-y divide-gray-200'>
                        <thead className='bg-gray-100'>
                          <tr>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'></th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Name
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Unit Price
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Quantity
                            </th>
                            {formikProps?.values?.AppoinmentType === 'Insurance' && (
                              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                Patient Share
                              </th>
                            )}
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Discount
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Tax
                            </th>
                            {/* <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {formikProps.values.items.map((item, index) => {
                            let maxQuantity = selectedOrderDetails?.items[index]?.quantity;
                            return (
                              <tr key={index} className='bg-white border-b'>
                                <td className='px-4 py-2'>
                                  <input
                                    name={`items[${index}].checked`}
                                    type='checkbox'
                                    className='h-[20px] w-[20px] rounded-sm'
                                    onChange={e =>
                                      formikProps.setFieldValue(`items[${index}].checked`, e?.target?.checked)
                                    }
                                  />
                                </td>
                                <td className='px-4 py-2'>
                                  <Tooltip
                                    title={item?.service_desc || item?.standard_description}
                                    className='text-[12px]'
                                  >
                                    {item?.service_desc || item?.standard_description}
                                  </Tooltip>
                                </td>
                                <td className='px-4 py-2'>
                                  <SharedInput
                                    type='text'
                                    name={`items[${index}].unit_price`}
                                    value={item.unit_price}
                                    className='!w-20'
                                    readOnly
                                    min={0}
                                  />
                                </td>
                                <td className='px-4 py-2'>
                                  <SharedInput
                                    type='number'
                                    name={`items[${index}].quantity`}
                                    value={item.quantity}
                                    className='!w-20'
                                    min={0}
                                    onChange={e => {
                                      const inputVal = parseFloat(e?.target?.value) || 0;
                                      const maxVal = inputVal > maxQuantity ? maxQuantity : inputVal;
                                      formikProps.setFieldValue(`items[${index}].quantity`, maxVal);
                                    }}
                                  />
                                </td>
                                {formikProps?.values?.AppoinmentType === 'Insurance' ? (
                                  <td className='px-4 py-2'>
                                    <SharedInput
                                      name={`items[${index}].patient_share`}
                                      value={item.patient_share}
                                      type='number'
                                      className='!w-24'
                                      readOnly
                                    />
                                  </td>
                                ) : null}
                                <td className='px-4 py-2'>
                                  <div className='flex items-center space-x-2'>
                                    <SharedDropdown
                                      name={`items[${index}].discount_type`}
                                      value={item.discount_type}
                                      data={typeOptions}
                                      optionLabel='select'
                                      disabled={true}
                                      className='!w-24'
                                    />
                                    <SharedInput
                                      type='number'
                                      name={`items[${index}].discount_amount`}
                                      value={item.discount_amount}
                                      className='!w-20'
                                      min={0}
                                      readOnly
                                    />
                                  </div>
                                </td>

                                <td className='px-4 py-2'>
                                  <div className='flex w-full'>
                                    <button
                                      type='button'
                                      disabled
                                      onClick={() => {
                                        formikProps.setFieldValue(`items[${index}].tax_check`, false);
                                      }}
                                      className={`w-[50%] border px-1.5 py-2 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                        formikProps.values.items[index].tax_check === false
                                          ? '!bg-primary !border-primary text-white'
                                          : ''
                                      }`}
                                    >
                                      False
                                    </button>
                                    <button
                                      type='button'
                                      disabled
                                      onClick={() => {
                                        formikProps.setFieldValue(`items[${index}].tax_check`, true);
                                      }}
                                      className={`w-[50%] border px-1.5 py-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                        formikProps.values.items[index].tax_check === true
                                          ? '!bg-primary !border-primary text-white'
                                          : ''
                                      }`}
                                    >
                                      True
                                    </button>
                                  </div>
                                </td>
                                {/* <td className='px-4 py-2'>
                                <Button
                                  color='error'
                                  onClick={() => {
                                    const newItems = formikProps.values.items.filter((_, i) => i !== index);
                                    formikProps.setFieldValue('items', newItems);
                                  }}
                                >
                                  <RiDeleteBin5Line />
                                </Button>
                              </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div className='flex justify-end mt-6'>
                  <Button
                    variant='contained'
                    type='submit'
                    size='large'
                    style={{
                      backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                    }}
                    className='px-6 py-2 text-white font-semibold rounded-md shadow-sm hover:shadow-md transition-all duration-300'
                  >
                    {appointmentBillToPay ? 'Pay' : 'Create Special Visit'}
                  </Button>
                </div>
              </Form>
              <AppLoader setShowLoader={setLoading} showLoader={loading} />
            </div>

            {validate !== '' && paidToday && (
              <div className='w-1/4 bg-white rounded-lg shadow-md p-3 flex flex-col space-y-4'>
                {/* Map Section */}
                <div className='card bg-white shadow-md border border-gray-300 rounded-lg p-2'>
                  {/* Flex container to align the heading and search bar in a row */}
                  <div className='flex flex-col items-start mb-2'>
                    <h2 className='text-lg font-semibold'>Location Map</h2>
                    <div className='w-auto'>
                      {/* Compact Search bar */}
                      <input
                        type='text'
                        className='border border-gray-300 rounded-lg py-1 px-2 text-sm'
                        placeholder='Search location...'
                      />
                    </div>
                  </div>
                  <div id='map' className='mt-2'>
                    <div className='map-container'>
                      <iframe
                        src='https://www.openstreetmap.org/export/embed.html?bbox=144.95165634155273%2C-37.82035453271387%2C144.95828247070312%2C-37.81530959306547&layer=mapnik&marker=-37.81782911897138%2C144.95496940612793'
                        width='100%'
                        height='200'
                        style={{ border: 0 }}
                        allowFullScreen=''
                        loading='lazy'
                      ></iframe>
                    </div>
                  </div>
                </div>

                {/* Bill Summary Section */}
                {formikProps?.values?.listInvoices && (
                  <div className='space-y-3'>
                    <h3 className='text-xl font-medium mb-3 text-gray-800'>Bill Summary</h3>
                    <div className=''>
                      {formikProps?.values?.AppoinmentType === 'Insurance' && (
                        <div className='bg-gray-50 p-2 rounded-lg'>
                          <div className='flex justify-between'>
                            <h4 className='text-md font-medium text-gray-700'>Total Discount:</h4>
                            <span className='font-semibold'>
                              {formikProps?.values?.AppoinmentType === 'Cash'
                                ? formikProps?.values?.items
                                    ?.map(itm => {
                                      const discountAmount = Number(itm?.discount_amount) || 0;
                                      const discountType = itm?.discount_type;
                                      let discount = 0;

                                      if (discountType === 'percentage') {
                                        discount =
                                          (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) / 100;
                                      } else if (discountType === 'fixed') {
                                        discount = discountAmount;
                                      }

                                      return discount;
                                    })
                                    .reduce((acc, curr) => acc + curr, 0)
                                : calculateShares(formikProps, maxLimit).totals?.discountedAmount}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className='bg-gray-50 p-2 rounded-lg'>
                        <h4 className='text-md font-medium text-gray-700'>Cash Bill</h4>
                        <div className='space-y-1'>
                          <div className='flex justify-between'>
                            <span className='text-gray-600 text-sm'>Amount:</span>
                            <span className='font-semibold text-sm'>
                              {formikProps?.values?.AppoinmentType === 'Cash'
                                ? calculateCashDetails(formikProps)?.totals?.patientShareTotal
                                : calculateShares(formikProps, maxLimit).totals?.patientShareTotal}
                            </span>
                          </div>
                          {formikProps?.values?.AppoinmentType === 'Cash' && (
                            <div className='flex justify-between'>
                              <span className='text-gray-600 text-sm'>Discount:</span>
                              <span className='font-semibold text-sm'>
                                {formikProps?.values?.AppoinmentType === 'Cash'
                                  ? calculateCashDetails(formikProps)?.totals?.discountedAmount
                                  : calculateShares(formikProps, maxLimit).totals?.discountedAmount}
                              </span>
                            </div>
                          )}
                          <div className='flex justify-between'>
                            <span className='text-gray-600 text-sm'>Tax:</span>
                            <span className='font-semibold text-sm'>
                              {formikProps?.values?.AppoinmentType === 'Cash'
                                ? calculateCashDetails(formikProps)?.totals?.patientTaxTotal
                                : calculateShares(formikProps, maxLimit).totals?.patientTaxTotal}
                            </span>
                          </div>
                          <div className='flex justify-between text-md font-semibold'>
                            <span className='text-gray-600'>Net Total:</span>
                            <span>
                              {/* {formikProps?.values?.AppoinmentType === "Cash"
                        ? calculateCashDetails(formikProps)?.totals
                            ?.patientShareTotal -
                          calculateCashDetails(formikProps, maxLimit)
                            ?.totals?.discountedAmount +
                          calculateCashDetails(formikProps, maxLimit).totals
                            ?.patientTaxTotal
                        : calculateShares(formikProps, maxLimit).totals
                            ?.patientShareTotal +
                          calculateShares(formikProps, maxLimit).totals
                            ?.patientTaxTotal} */}
                              {formikProps?.values?.AppoinmentType === 'Cash'
                                ? Number(
                                    calculateCashDetails(formikProps)?.totals?.patientShareTotal -
                                      calculateCashDetails(formikProps, maxLimit)?.totals?.discountedAmount +
                                      calculateCashDetails(formikProps, maxLimit)?.totals?.patientTaxTotal || 0
                                  ).toFixed(2)
                                : Number(
                                    calculateShares(formikProps, maxLimit)?.totals?.patientShareTotal +
                                      calculateShares(formikProps, maxLimit)?.totals?.patientTaxTotal || 0
                                  ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>

                      {validate === 'Insurance' && paidToday && (
                        <div className='bg-gray-50 p-2 rounded-lg'>
                          <h4 className='text-md font-medium text-gray-700'>Company Bill</h4>
                          <div className='space-y-1'>
                            <div className='flex justify-between'>
                              <span className='text-gray-600 text-sm'>Amount:</span>
                              <span className='font-semibold text-sm'>
                                {calculateShares(formikProps, maxLimit).totals?.companyShareTotal}
                              </span>
                            </div>
                            <div className='flex justify-between'>
                              <span className='text-gray-600 text-sm'>Tax:</span>
                              <span className='font-semibold text-sm'>
                                {calculateShares(formikProps, maxLimit).totals?.companyTaxTotal}
                              </span>
                            </div>
                            <div className='flex justify-between text-md font-semibold'>
                              <span className='text-gray-600'>Net Total:</span>
                              <span>
                                {(
                                  parseFloat(calculateShares(formikProps, maxLimit).totals?.companyShareTotal || 0) +
                                  parseFloat(calculateShares(formikProps, maxLimit).totals?.companyTaxTotal || 0)
                                )?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <AppLoader setShowLoader={setLoading} showLoader={loading} />
                  </div>
                )}
                <AppLoader setShowLoader={setLoading} showLoader={loading} />
              </div>
            )}
          </div>
        )}
      </Formik>
    </>
  );
}

export default HomeAppointment;
